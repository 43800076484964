import styled from "styled-components"
import { lighten, readableColor } from "polished"
import colors from "shared/utils/colors"

export const Heading = styled.h3`
  color: ${colors.grayAluminium};
  opacity: 0.8;
  margin: 0 0 1rem 0;
  padding: 2rem 0 0 0;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 1.7rem;
  line-height: 3.4rem;
`

export const Table = styled.table`
  font-size: 1.6rem;
  margin-top: 1rem;
  width: 100%;
  margin-bottom: 1rem;
`

export const TR = styled.tr`
  &:nth-of-type(odd) {
    background-color: ${colors.grayWhiteSmoke};
    transition: background-color 0.2s ease-out;
  }
  &:hover {
    cursor: pointer;
    transition: background-color 0.2s ease-in;
    background-color: ${props => lighten(0.3, props.theme.colors.secondary)};
    color: ${props => readableColor(props.theme.colors.secondary)};
  }
`

export const TH = styled.th`
  text-align: left;
  vertical-align: bottom;
  border-bottom: 2px solid ${colors.grayAliceBlue};
  padding: 8px;
`

export const TD = styled.td`
  transition: color 0.1s ease-in;
  padding: 8px;
  line-height: 1.4;
  border-top: 1px solid ${colors.grayAliceBlue};
`

export const SortArrow = styled.span`
  margin-left: 1rem;
  font-size: 1.4rem;
  color: ${props => props.theme.colors.primary};
`

export const TableCell = styled.td`
  transition: color 0.1s ease-in;
  padding: 8px;
  line-height: 1.4;
  border-top: 1px solid ${colors.grayAliceBlue};
  ${props => props.centered && `text-align: center;`};
`

export const ShownRow = styled.tr`
  background-color: ${props => lighten(0.3, props.theme.colors.secondary)};
  color: ${props => readableColor(props.theme.colors.secondary)};
  table {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    width: 100%;
  }

  td,
  th {
    background-color: ${props => lighten(0.3, props.theme.colors.secondary)};
    color: ${props => readableColor(props.theme.colors.secondary)};
    font-size: 0.95em;
    padding: 0px 5px;
    text-align: center;
  }
`
