import React from "react"
import { useQuery } from "@apollo/client"
import FETCH_DRILLDOWN_COUNTS from "mill/graphql/FetchDrilldownCounts"
import { useLocation, useParams } from "react-router-dom"
import Link from "shared/components/Link"
import { darken } from "polished"
import styled from "styled-components"
import useDrilldown from "mill/hooks/useDrilldown"

const Selections = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  margin: 2rem -1rem 2rem -1rem;
`

const Selection = styled.li`
  flex: 1;
  display: flex;
  padding: 0 1rem;

  h3,
  p {
    font-size: 0.9em;
    margin: 0;
  }

  a {
    flex: 1;
    background: white;
    border-radius: 3px;
    padding: 1.8rem 2rem;
    color: #909090;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0);
    transition: box-shadow 500ms;
    border: 2px solid transparent;

    &:hover {
      cursor: pointer;
      box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
      border: 2px solid #eee;
      h3 {
        color: ${darken(0.2, "#909090")};
      }
    }
  }

  h3 {
    color: #535353;
    font-weight: normal;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    border-bottom: 2px solid #eee;
  }

  strong {
    font-weight: normal;
    color: ${darken(0.2, "#909090")};
  }

  ${props =>
    props.isActive &&
    `
    a {
      box-shadow: 3px 3px 10px rgba(0,0,0,0.05);
      border: 2px solid #eee;
      &:hover {
        h3 { color: #535353; }
      }
    }
    h3 {
      font-weight: bold;
    }
`}
`

const Component = () => {
  const params = useParams()
  const { clusterId, clusterType } = params
  const campaignId = clusterType == "campaigns" ? clusterId : params.campaignId

  const { data, loading } = useQuery(FETCH_DRILLDOWN_COUNTS, {
    variables: {
      campaignId,
      clusterId,
      clusterType
    }
  })

  const { currentDrilldownKey, visibleDrilldowns } = useDrilldown()

  const drilldownPath = drilldown => {
    if (currentDrilldownKey == drilldown) {
      return location.pathname
    } else {
      return `${location.pathname}?drilldown=${drilldown}`
    }
  }

  const drilldownCount = key => {
    if (loading || !data) return "-"
    return data.learnerDrilldowns[`${key}Count`]
  }

  return (
    <Selections>
      {visibleDrilldowns.map(drilldown => {
        return (
          <Selection
            key={drilldown.key}
            isActive={currentDrilldownKey == drilldown.key}>
            <Link to={drilldownPath(drilldown.key)}>
              <h3>{drilldown.title}</h3>
              <p>
                <strong>{drilldownCount(drilldown.key)} learners</strong>{" "}
                {drilldown.description}
              </p>
            </Link>
          </Selection>
        )
      })}
    </Selections>
  )
}
export default Component
