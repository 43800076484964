import React from "react"
import Link from "shared/components/Link"
import useDrilldown from "mill/hooks/useDrilldown"
import styled from "styled-components"
import { darken, readableColor } from "polished"

const Container = styled.p`
  background-color: #daedf9;
  color: ${darken(0.7, "#daedf9")};
  padding: 1rem 2rem;
  font-size: 1.4rem;
  font-family: ${props => props.theme.fonts.body};
  border-radius: 3px;

  a {
    color: ${darken(0.7, "#daedf9")};
    font-weight: bold;
  }
`

const SelectedDrilldownInverter = () => {
  const { currentDrilldown, currentDrilldownKey } = useDrilldown()

  if (!currentDrilldownKey) {
    return null
  }

  return (
    <Container>
      {currentDrilldown.contextStatement}{" "}
      {currentDrilldown.invertStatement && (
        <Link
          to={`${location.pathname}?drilldown=${currentDrilldown.invertedKey}`}>
          {currentDrilldown.invertStatement}
        </Link>
      )}
    </Container>
  )
}

export default SelectedDrilldownInverter
